export default Object.freeze({
  HOME: "/",
  ABOUT: "/",
  SERVICES: "/services",
  CONTACT: "/contact",
  GET_QUOTE: "/get-quote",
  INSTAGRAM: "",
  LINKEDIN: "",
  TWITTER: "",
  FACEBOOK: "",
});
