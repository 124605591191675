import React from "react";
import { Link } from "react-router-dom";
import routes from "routes";

function NavigationMenu({ openNavigationMenu, setOpenNavigationMenu }) {
  const closeNavigationMenu = () => {
    setOpenNavigationMenu(false);
  };

  return (
    <div className={`navigation-menu ${openNavigationMenu ? "active" : ""}`}>
      <div className="inner">
        <div className="sides">
          <ul>
            <li>
              <b>Social :</b>
              <Link to={routes.LINKEDIN} target="_blank" className="pointer">
                LINKEDIN
              </Link>
              <Link to={routes.TWITTER} target="_blank" className="pointer">
                TWITTER
              </Link>
              <Link target="_blank" to={routes.FACEBOOK} className="pointer">
                FACEBOOK
              </Link>
              <Link target="_blank" to={routes.INSTAGRAM} className="pointer">
                INSTAGRAM
              </Link>
            </li>
            <li>
              <b>Email :</b>
              <a _ngcontent-urw-c58="" href="mailto:info@codecraft247.com">
                info@codecraft247.com
              </a>
              <br />
            </li>
          </ul>
        </div>
        <div className="side-menu">
          <ul>
            <li>
              <Link to={routes.ABOUT} onClick={closeNavigationMenu}>
                <span className="d-none">01.</span> About Us
              </Link>
            </li>
            <li>
              <Link to={routes.SERVICES} onClick={closeNavigationMenu}>
                <span className="d-none">02.</span> Services
              </Link>
            </li>
            <li>
              <Link to={routes.CONTACT} onClick={closeNavigationMenu}>
                <span className="d-none">03.</span> Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavigationMenu;
