import { Link } from "react-router-dom";
import routes from "routes";

function Footer() {
  const date = new Date();
  const socialLinks = [
    {
      label: "LinkedIn",
      link: routes.LINKEDIN,
    },
    {
      label: "Twitter",
      link: routes.TWITTER,
    },
    {
      label: "Instagram",
      link: routes.INSTAGRAM,
    },
    {
      label: "Facebook",
      link: routes.FACEBOOK,
    },
  ];

  const handleScrollTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="career wow fadeInUp"
              style={{
                visibility: "visible",
                animationName: "fadeInUp",
              }}
            >
              <h6>Bubbles in mind?</h6>
              <h2>
                Let's create an amazing <br />
                project together!
              </h2>
              <div
                className="custom-btn white data-fade"
                style={{
                  opacity: 1,
                  transform: "translate(0px, 0px)",
                }}
              >
                <Link to={routes.CONTACT}>
                  Email Now<span></span> <i></i>
                </Link>
              </div>
              {/* <div className="custom-link white"><a href="contact.html" target="_blank">Email Now</a> <span></span> <i></i>
          </div> */}
            </div>
          </div>
          <div className="block col-lg-5 wow fadeInUp">
            <figure className="logo sticky">
              <img src="images/logo-light.png" alt="Image" />
            </figure>
          </div>
          <div className="block col-lg-4 col-md-6 wow fadeInUp">
            <h5>Join us</h5>
            <div className="links">
              {socialLinks.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className="link"
                  target="_blank"
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="block col-lg-3 col-md-6 wow fadeInUp">
            <h5>Say Hello</h5>
            <div className="links">
              <a
                _ngcontent-urw-c58=""
                href="mailto:info@codecraft247.com"
                className="link"
              >
                info@codecraft247.com
              </a>
            </div>
          </div>
          <div
            className="col-12 wow fadeInUp"
            style={{
              visibility: "visible",
              animationName: "fadeInUp",
            }}
          >
            <div className="sub-footer">
              <ul>
                <li>
                  <Link to={routes.ABOUT}>About Us</Link>
                </li>
                <li>
                  <Link
                    to={routes.SERVICES}
                    className="dribble"
                    onClick={handleScrollTop}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link to={routes.CONTACT} onClick={handleScrollTop}>
                    Contact
                  </Link>
                </li>
              </ul>
              <span>
                © {date.getFullYear()} 111technology LTD - All rights Reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
