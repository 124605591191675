import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

function RecentNews() {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleClickPrevSlide = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleClickNextSlide = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex + 1);
  };

  return (
    <section className="recent-news">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-12 wow fadeInUp">
            <div className="titles p-0">
              {/* <span className="main-title"></span>  */}
              <h3 className="font-weight-bold">
                We’ve been trully privileged to have worked with great people
                across multiple industries
              </h3>
            </div>
          </div>
          <div className="col-12 wow fadeInUp">
            <div className="testimonial-section">
              <div className="swiper-container">
                <div className="pagination-sec">
                  <div
                    className="swiper-button-next"
                    onClick={handleClickNextSlide}
                    style={{ backgroundImage: "url(images/right-arrow.png)" }}
                  ></div>
                  <div
                    className="swiper-button-prev"
                    onClick={handleClickPrevSlide}
                    style={{ backgroundImage: "url(images/left-arrow.png)" }}
                  ></div>
                  <div className="swiper-pagination">{`${currentSlide}/5`}</div>
                </div>
                <Swiper
                  ref={sliderRef}
                  slidesPerView={1}
                  loop={true}
                  spaceBetween={1}
                  autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                    type: "fraction",
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  onSlideChange={handleSlideChange}
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide className="swiper-slide">
                      <div className="testimonial-grid">
                        <div className="testimonial-content">
                          <p>
                            Code Craft is an absolute gem! Working with them has
                            been an incredibly positive experience. Their
                            exceptional communication skills ensure a seamless
                            collaboration, and their talent for crafting
                            stunning web designs is truly impressive. They
                            consistently deliver outstanding results, making
                            them a true asset to any team!”
                          </p>
                        </div>
                        <div className="testimonial-details">
                          <span className="testimonial-img">
                            <img src="images/domingo.webp" alt="imgaes" />
                          </span>
                          <span className="testimonial-author">
                            <b>Domingo Flores</b>
                            <i className="main-title">
                              Co-Founder / CEO, Steady & Studios
                            </i>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="testimonial-grid">
                        <div className="testimonial-content">
                          <p>
                            Code Craft are rare breed of team/agency who care as
                            much about your product as you do, consistently set
                            a higher bar for design, help make product
                            decisions, and ultimately help your dream come alive
                            from proposal to finished product”
                            <br />
                            <br />
                          </p>
                        </div>
                        <div className="testimonial-details">
                          <span className="testimonial-img">
                            <img src="images/amulya.jpeg" alt="imgaes" />
                          </span>
                          <span className="testimonial-author">
                            <b>Amulya Parmar</b>
                            <i className="main-title">
                              CEO of LeaseMagnets & Tour (YC S21)
                            </i>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="testimonial-grid">
                        <div className="testimonial-content">
                          <p>
                            “They are highly responsive to my needs. Also,they
                            led a simple and straightforward approach, ensuring
                            a smooth workflow.”
                          </p>
                        </div>
                        <div className="testimonial-details">
                          <span className="testimonial-img">
                            <img src="images/json.png" alt="imgaes" />
                          </span>
                          <span className="testimonial-author">
                            <b>Jason Hunt</b>
                            <i className="main-title">
                              BD, Amazon & Investor for Start-Ups
                            </i>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="testimonial-grid">
                        <div className="testimonial-content">
                          <p>
                            “Brought in Code Craft on Coinlytics and they hit it
                            out of the park, Excellent team and would absolutely
                            work with them again”
                          </p>
                        </div>
                        <div className="testimonial-details">
                          <span className="testimonial-img">
                            <img src="images/jonathan.png" alt="imgaes" />
                          </span>
                          <span className="testimonial-author">
                            <b>Jonathan Zufi</b>
                            <i className="main-title">
                              Creator, Coinlytics & CTO - Equity Estates Fund
                            </i>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="testimonial-grid">
                        <div className="testimonial-content">
                          <p>
                            "Great work Code Craft! The final design for case
                            studies pages looks awesome" <br />
                            <br />
                          </p>
                        </div>
                        <div className="testimonial-details">
                          <span className="testimonial-img">
                            <img src="images/danielle.png" alt="imgaes" />
                          </span>
                          <span className="testimonial-author">
                            <b>Danielle Tschudi</b>
                            <i className="main-title">
                              Founder & CEO at Outshifter
                            </i>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RecentNews;
